import React from 'react'
import { Row} from 'react-bootstrap'
import './Sejarah.css';

export default function Sejarah() {
    return (
        <div>
            <section className="Container-Sejarah">
                <Row className="Sejarah">
                        <h1>Tujuan</h1>
                </Row>
                <Row className="Sejarah">
                    <p>
                    Turut serta membantu pemerintah Republik Indonesia dalam bidang pendidikan untuk meningkatkan sumber daya manusia dalam rangka mencerdaskan kehidupan bangsa dan meningkatkan kesejahteraan masyarakat dengan membentuk manusia beriman dan bertaqwa serta ber akhlaqul karimah dengan bersumber pada al Qur’an dan as Sunnah.
                   </p>
                </Row>
            </section>
        </div>
    )
}
