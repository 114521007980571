import React from 'react'
import { Row } from 'react-bootstrap'
import '../CSSTKIT/Tujuan.css'

export default function Tujuan() {
    return (
        <div>
            <section className="Container-Tujuan">
                <Row className="Tujuan">
                        <h1>Tujuan Sekolah</h1>
                        <hr/>
                </Row>
                <div className="Tujuan">
                    <h5>Tujuan Jangka Pendek :</h5>
                        <ul>
                            <li>Membantu anak didik yang berakhlak, berbudi pekerti dan berkarakter.</li>
                            <li>Mencetak generasi cerdas, berwawasan luas melalui kreativitas, bakat, minat, dan sikap mandiri.</li>
                            <li>Memotivasi anak didik dan guru agar mampu mengukir prestasi.</li>
                        </ul>

                    <h5>Tujuan Jangka Menengah :</h5>
                        <ul>
                            <li>Meningkatkan kompetensi guru agar tercipta guru yang profesional.</li>
                            <li>Menyiapkan peserta didik untuk melanjutkan ke jenjang SD matang secara mental dan kemandirian.</li>
                        </ul>
                    <h5>Tujuan Jangka Panjang :</h5>
                        <p>Menjadikan Taman Kanak-kanak Terpadu dan menjadi sekolah teladan untuk dijadikan contoh Taman Kanak-kanak lain, dari tingkat kecamatan sampai dengan tingkat provinsi.</p>
                </div>
            </section>
        </div>
    )
}
