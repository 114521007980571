import React from 'react'
import { Component } from 'react'
import TkitAkademik from 'parts/Akademik/TkitAkademik'
import Footer from 'parts/Footer/Footer'

export default class AkademikPage extends Component {
    render() {
    return (
        <>
           <TkitAkademik/>
           <Footer />
        </>
        )
    }
}
