import React from 'react'
import {Col, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import ImageTkit1Akademik from 'assets/images/homepage/tkit-1.svg'
import ImageTkit2Akademik from 'assets/images/homepage/tkit-2.svg'
import ImageTkit1AkademikMd from 'assets/images/homepage/tkit-1-md.svg'
import ImageTkit2AkademikMd from 'assets/images/homepage/tkit-2-md.svg'
import './TkitAkademik.css'


export default function TkitAkademik() {
    return (
        <div>
            <section className="Container-Akademik">
            <Link to="/tkit-1" className="Link-Tkit">
                <Row className="Akademik">
                    <Col md={5} className="Akademik-Img Akademik-Img-Md">
                        <img src={ImageTkit1Akademik} alt="TKIT-1" />
                    </Col>
                    <Col md={5} className="Akademik-Img Akademik-Img-Sm">
                        <img src={ImageTkit1AkademikMd} alt="TKIT-1" />
                    </Col>
                    
                    <Col sm={12} md={7} className="Akademik-Text position-block " >
                        <h1>TKIT AL - ISTIQOMAH 1</h1>
                        <hr/>
                        <img src={ImageTkit1Akademik} alt="TKIT-1" />
                        <p>TKIT Al - Istiqomah 1 didirikan pada tahun 1981 dibawah naungan Yayasan Pendidikan Insan Istiqomah, 
                            yang berada di Jalan Nanas Raya no. 7 Perumnas 1 kelurahan Cibodasari Kecamatan Cibodas 
                            Kota Tangerang. Meskipun sudah 40 tahun berdiri, TKIT Al - Istiqomah 1 masih dipercaya oleh 
                            masyarakat sampai dengan sekarang, tentunya hal ini karena TKIT Al - Istiqomah 1 selalu berinovasi 
                            dalam pembelajaran, dengan menyesuaikan perkembangan zaman tanpa meninggalkan karakter dan 
                            nilai-nilai akidah islami.
                            Dengan izin Allah SWT pada tahun 2019, TKIT Al - Istiqomah 1  mendapatkan akreditasi A (Unggul) dan 
                            pada tahun 2021 TKIT Al - Istiqomah menjadi sample dari kementrian Pendidikan dan Kebudayaan menjadi salah 
                            satu sekolah Penjaminan Mutu dan seleksi sekolah Penggerak Angkatan 2.</p>
                        <Row>
                            <Link to="/tkit-1" className="btn btn-tkit" hasShadow>
                                See More
                            </Link>
                        </Row>
                    </Col>
                    
                </Row>
                </Link>

                <Link to="/tkit-2" className="Link-Tkit">
                <Row className="Akademik Akademik-2">
                    <Col sm={12} md={7} className="Akademik-Text position-block" >
                        <h1>TKIT AL - ISTIQOMAH 2</h1>
                        <hr/>
                        <img src={ImageTkit2Akademik} alt="TKIT-2" />
                        <p>TKIT Al - Istiqomah 2 merupakan sekolah islam yang menanamkan karakter dasar islami sejak usia dini 
                           melalui pembiasaan-pembiasaan harian dengan melatih kemandirian  serta mengenalkan pembelajaran 
                           Al Qur'an dengan cara menyenangkan.</p>
                        <Row>
                            <Link to="/tkit-2" className="btn btn-tkit " hasShadow>
                                See More
                            </Link>
                        </Row>
                    </Col>
                   
                    <Col md={5} className="Akademik-Img-2 Akademik-Img-Md">
                        <img src={ImageTkit2Akademik} alt="TKIT-2" />
                    </Col>
                    <Col md={5} className="Akademik-Img-2 Akademik-Img-Sm">
                        <img src={ImageTkit2AkademikMd} alt="TKIT-2" />
                    </Col>
                    
                </Row>
                </Link>
            </section>
        </div>
    )
}
