import React from 'react'
import { Row, Col} from 'react-bootstrap'
import ImageHero from 'assets/images/homepage/hero.svg'
import './Hero.css';

export default function Hero(props) {
    return (
        <div>
            <section className="container-hero ">
                <Row className="hero">
                    <Col sm={12} md={6}  className="hero-text">
                       
                        <h1>Bangun Akhlak &</h1>
                        <h2>Kepribadian Anak di Usia Dini</h2>
                        
                        <p>Bersama kami tanamkan karakter dasar islam pada anak usia dini.
                        Dengan muatan lokal konsep akidah dan nilai-nilai religi islami. 
                        </p>
                        <a className="btn btn-hero px-4"  href="https://ppdb.istiqomah.sch.id/" target="blank_">
                            Daftar Sekarang!
                        </a>
                    </Col>
                    <Col sm={12} md={6} className="hero-img position-block" >
                         <img src={ImageHero} alt="Room with couches" className="img-fluid img-hero mySlides
                            position-absolute"/>
                    </Col>
                </Row>
            </section>
        </div>
    )
}
