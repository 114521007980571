import React from 'react'
import {Row, Col} from 'react-bootstrap'
import { Fade } from 'react-reveal'
import IconGuru from 'assets/images/icon-choose-us/guru.png'
import IconAgama from 'assets/images/icon-choose-us/agama.png'
import IconIqro from 'assets/images/icon-choose-us/iqro.png'
import IconShalat from 'assets/images/icon-choose-us/shalat.png'
import IconSentra from 'assets/images/icon-choose-us/sentra.png'
import IconEmosional from 'assets/images/icon-choose-us/emosional.png'
import IconKognitif from 'assets/images/icon-choose-us/kognitif.png'
import IconMotorik from 'assets/images/icon-choose-us/motorik.png'
import './Choose.css'

export default function Choose() {
    return (
        <div>
            <section className="Container-Choose  pb-5">
                    <div className="Title">
                        <h2>Kenapa Pilih Kami</h2>    
                    </div>
                    <div className="Subtitle">
                        <p>Kami memiliki beberapa keunggulan dalam program pembelajaran, diantaranya :</p>    
                    </div>

                    <Row className="ChooseUs-Card justify-content-center pb-2">
                        <Col xs={6} sm={6} md={3}>
                            <Fade delay={50}>
                            <div className="Card">
                                <img src={IconGuru} className="Card-img-top" />
                                <div className="Card-Body">
                                    <h5 className="Card-Title">Guru Profesional</h5>
                                    <p className="Card-Text">Berpengalaman dengan lulusan Sarjana Pendidikan Guru Anak Usia Dini.</p>
                                </div>
                            </div>
                            </Fade>
                        </Col>
                        <Col xs={6} sm={6} md={3}>
                            <Fade delay={100}>
                            <div className="Card">
                                <img src={IconAgama} className="Card-img-top" />
                                <div className="Card-Body">
                                    <h5 className="Card-Title">Nilai Norma & Agama</h5>
                                    <p className="Card-Text">Murid akan dibekali dasar-dasar nilai norma dan agama. </p>
                                </div>
                            </div>
                           </Fade>
                        </Col>
                        <Col xs={6} sm={6} md={3}>
                            <Fade delay={200}>
                            <div className="Card Card-sm">
                                <img src={IconIqro} className="Card-img-top" />
                                <div className="Card-Body">
                                    <h5 className="Card-Title">Program Iqro & Tahfidz</h5>
                                    <p className="Card-Text">Pengenalan huruf hijaiyah dan pembiasaan membaca surah pendek.</p>
                                </div>
                            </div>
                            </Fade>
                        </Col>
                        <Col xs={6} sm={6} md={3}>
                            <Fade delay={300}>
                            <div className="Card Card-sm">
                                <img src={IconShalat} className="Card-img-top" />
                                <div className="Card-Body">
                                    <h5 className="Card-Title">Shalat</h5>
                                    <p className="Card-Text">Anak akan diajarkan tata cara shalat dan bacaan shalat.</p>
                                </div>
                            </div>
                            </Fade>
                        </Col>

                    
                    </Row>

                    <Row className="ChooseUs-Card justify-content-center pt-5">

                    <Col xs={6} sm={6} md={3}>
                        <Fade delay={100}>
                        <div className="Card">
                            <img src={IconSentra} className="Card-img-top" />
                            <div className="Card-Body">
                                <h5 className="Card-Title">Pembelajaran Sentra</h5>
                                <p className="Card-Text">Anak diberikan kesempatan untuk bermain secara aktif dan kreatif. </p>
                            </div>
                        </div>
                        </Fade>
                    </Col>

                    <Col xs={6} sm={6} md={3}>
                        <Fade delay={200}>
                        <div className="Card">
                            <img src={IconEmosional} className="Card-img-top" />
                            <div className="Card-Body">
                                <h5 className="Card-Title">Social Emosional</h5>
                                <p className="Card-Text">Kemampuan anak dalam mengelola dan mengekspresikan emosi. </p>
                            </div>
                        </div>
                        </Fade>
                    </Col>

                    <Col xs={6} sm={6} md={3}>
                        <Fade delay={300}>
                        <div className="Card Card-sm">
                            <img src={IconKognitif} className="Card-img-top" />
                            <div className="Card-Body">
                                <h5 className="Card-Title">Kognitif</h5>
                                <p className="Card-Text">Anak mampu mencari tahu, berpikir, dan mengeksplorasi sesuatu.</p>
                            </div>
                        </div>
                        </Fade>
                    </Col>

                    <Col xs={6} sm={6} md={3}>
                        <Fade delay={400}>
                        <div className="Card Card-sm">
                            <img src={IconMotorik} className="Card-img-top" />
                            <div className="Card-Body">
                                <h5 className="Card-Title">Fisik Motorik</h5>
                                <p className="Card-Text"> Proses tumbuh kembang kemampuan gerak seorang anak.</p>
                            </div>
                        </div>
                        </Fade>
                    </Col>
                    
                    
                </Row>
            </section>
        </div>
    )
}
