import React from 'react'
import { Component } from 'react'
import Tkit2KepalaSekolah from 'parts/Akademik/TKIT2/Tkit2KepalaSekolah'
import VisiMisiTkit2 from 'parts/Akademik/TKIT2/VisiMisiTkit2'
import KegiatanTahunanTkit2 from 'parts/Akademik/TKIT2/KegiatanTahunanTkit2'
import EkskulTkit2 from 'parts/Akademik/TKIT2/EkskulTkit2'
import StaffPengajar from 'parts/Akademik/TKIT2/StaffPengajar'
import Footer from 'parts/Footer/Footer'

export default class Tkit2ProfilePage extends Component {
    render() {
    return (
        <>
           <Tkit2KepalaSekolah />
           <VisiMisiTkit2/>
           <KegiatanTahunanTkit2 />
           <EkskulTkit2 />
           <StaffPengajar />
           <Footer />
        </>
        )
    }
}
