import React, {useState} from 'react'
import CloseIcon from '@mui/icons-material/Close';
import ImageFasilitas1 from 'assets/images/fasilitas/building1.png'
import ImageFasilitas2 from 'assets/images/fasilitas/building2.png'
import ImageFasilitas3 from 'assets/images/fasilitas/kelas1.png'
import ImageFasilitas4 from 'assets/images/fasilitas/kelas2.png'
import ImageFasilitas5 from 'assets/images/fasilitas/playground1.png'
import ImageFasilitas6 from 'assets/images/fasilitas/playground2.png'
import './Fasilitas.css'

const Fasilitas = () => {

    let data =[
        {
            id: 1,
            imgSrc: ImageFasilitas1,
        },
        {
            id: 2,
            imgSrc: ImageFasilitas3,
        },
        {
            id: 3,
            imgSrc: ImageFasilitas5,
        },
        {
            id: 4,
            imgSrc: ImageFasilitas2,
        },
        {
            id: 5,
            imgSrc: ImageFasilitas4,
        },
        {
            id: 6,
            imgSrc: ImageFasilitas6,
        }
    ]

    const [model, setModel] = useState(false)
    const [tempimgSrc, setTempImgSrc] = useState('')

    const getImg = (imgSrc) => {
        setTempImgSrc(imgSrc);
        setModel(true);
    }


    return (
        <>
            <div className={model? "model open" : "model"}>
                <img src={tempimgSrc} />
                    <CloseIcon onClick={() => setModel(false)} />
            </div>

            <section className="Container-Fasilitas pt-3">

                <div className="Title">
                    <h2>Fasilitas</h2>    
                </div>
                
                <div className="Subtitle">
                    <p>Kami memiliki beberapa fasilitas untuk mendukung kegiatan belajar mengajar.</p>    
                </div>

                <div className="Image-Fasilitas">
                    {data.map((item, index) =>{
                        return(
                            <div className="pics" key={index} onClick={() => getImg(item.imgSrc)}>
                                <img src={item.imgSrc}/>
                            </div>
                            
                        )
                    })}
                </div>
            </section>
        </>
    )
}

export default Fasilitas;