import React from 'react'
import { Row, Col} from 'react-bootstrap'
import '../CSSTKIT/VisiMisiTkit.css'
import IconMisi1 from 'assets/images/misi-images/1.png'
import IconMisi2 from 'assets/images/misi-images/2.png'
import IconMisi3 from 'assets/images/misi-images/3.png'
import IconMisi4 from 'assets/images/misi-images/4.png'
import IconMisi5 from 'assets/images/misi-images/5.png'

export default function VisiMisiTkit() {
    return (
        <div>
            <section className="Container-VisiMisiTkit">
                <Row className="VisiTkit">
                        <h1> Visi </h1>
                        <hr /> 
                            <p>
                            Menjadi Taman Kanak - Kanak Islam Terpadu Unggul yang Melahirkan Generasi Mandiri Berakhlakul Karimah, Qur'ani Serta Berwawasan Imtaq dan Iptek.
                            </p>
                </Row>
                <Row className="MisiTkit">
                    <h1> Misi </h1>
                    <hr />
                </Row>
                <div className="MisiTkit">
                    
                    <Row className="Misi-Container">
                        <Row className="Line-Misi Line-1">
                            <Col xs={2} className="Img-Misi">
                            <img src={IconMisi1} alt="icon-misi"/>
                            </Col>
                            <Col xs={10} className="Text-Misi">
                            <p>Menanamkan Pendidikan Anak Sejak Dini Melalui Pembiasaan</p>
                            </Col>
                        </Row>
                        <Row className="Line-Misi ">
                            <Col xs={2} className="Img-Misi">
                            <img src={IconMisi2} alt="icon-misi"/>
                            </Col>
                            <Col xs={10} className="Text-Misi">
                            <p>Mengembangkan Kemandirian Anak Melalui Kegiatan Life Skill</p>
                            </Col>
                        </Row>
                        <Row className="Line-Misi">
                            <Col xs={2} className="Img-Misi">
                            <img src={IconMisi3} alt="icon-misi"/>
                            </Col>
                            <Col xs={10} className="Text-Misi">
                            <p>Memafasilitasi Kegiatan Belajar Mengajar yang Menyenangkan Sesuai Dengan Tahapan Perkembangan Anak.</p>
                            </Col>
                        </Row>
                    </Row>
                    
                </div>
            </section>
        </div>
    )
}
