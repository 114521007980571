import React from 'react'
import {Row, Col} from 'react-bootstrap'
import '../CSSTKIT/StaffPengajar.css'
import Pengajar1 from '../../../assets/images/profile-tkit-1/bu-cindy.png'
import Pengajar2 from '../../../assets/images/profile-tkit-1/bunda.png'
import Pengajar3 from '../../../assets/images/profile-tkit-1/bu-isna.png'
import Pengajar4 from '../../../assets/images/profile-tkit-1/bu-yati.png'
import Staff from '../../../assets/images/profile-tkit-1/bu-odih.png'

export default function StaffPengajar() {
    return (
        <div>
            <section className="Container-StaffPengajar">
                <Row className="Title-StaffPengajar">
                    <h1>Staff & Pengajar</h1>
                    <hr/>
                </Row>

                <Row className="StaffPengajar justify-content-center pb-2">
                    <Col xs={6} md={4}>
                        <div className="Card Card-StaffPengajar">
                            <img src={Pengajar1} className="Card-Img" />
                            <div className="Card-Body StaffPengajar-Text">
                                <h5 className="Card-Title">Neng Cindy, S.Pd</h5>
                                <p className="Card-Text">Guru TK A </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={4}>
                        <div className="Card Card-StaffPengajar">
                            <img src={Pengajar2} className="Card-Img" />
                            <div className="Card-Body StaffPengajar-Text">
                                <h5 className="Card-Title">Siti Ningrum, S.Pd.I</h5>
                                <p className="Card-Text">Guru TK B </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={4}>
                        <div className="Card Card-StaffPengajar">
                            <img src={Pengajar3} className="Card-Img" />
                            <div className="Card-Body StaffPengajar-Text">
                                <h5 className="Card-Title">Isnawati, S.Pd</h5>
                                <p className="Card-Text">Guru TK B </p>
                            </div>
                        </div>
                    </Col>
                

                    <Col xs={6} md={4}>
                        <div className="Card Card-StaffPengajar">
                            <img src={Pengajar4} className="Card-Img" />
                            <div className="Card-Body StaffPengajar-Text">
                                <h5 className="Card-Title">Rohayati, S.Pd</h5>
                                <p className="Card-Text">Guru TK B </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={4}>
                        <div className="Card Card-StaffPengajar">
                            <img src={Staff} className="Card-Img" />
                            <div className="Card-Body StaffPengajar-Text">
                                <h5 className="Card-Title">Odih Rodiah</h5>
                                <p className="Card-Text">Staff TU </p>
                            </div>
                        </div>
                    </Col>
                    </Row>
            </section>
        </div>
    )
}
