import React from 'react'
import { Row, Col} from 'react-bootstrap'
import '../CSSTKIT/VisiMisiTkit.css'
import IconMisi1 from 'assets/images/misi-images/1.png'
import IconMisi2 from 'assets/images/misi-images/people.png'
import IconMisi3 from 'assets/images/misi-images/3.png'

export default function VisiMisiTkit() {
    return (
        <div>
            <section className="Container-VisiMisiTkit">
                <Row className="VisiTkit">
                        <h1> Visi </h1>
                        <hr />
                            <p>
                                Menjadi Taman Kanak - Kanak Islam Terpadu Unggul yang Melahirkan Generasi Mandiri Berakhlakul Karimah, Qur'ani Serta Berwawasan Imtaq dan Iptek.
                            </p>
                </Row>


                <Row className="MisiTkit">
                    <h1> Misi </h1>
                    <hr />
                </Row>
                
                <div className="MisiTkit">
                    
                    <Row className="Misi-Container">
                        <Row className="Line-Misi Line-1">
                            <Col xs={2} className="Img-Misi">
                            <img src={IconMisi1} alt="icon-misi"/>
                            </Col>
                            <Col xs={10} className="Text-Misi">
                            <p>Menciptakan Suasana Belajar yang Aktif dan Menyenangkan.</p>
                            </Col>
                        </Row>
                        <Row className="Line-Misi ">
                            <Col xs={2} className="Img-Misi">
                            <img src={IconMisi2} alt="icon-misi"/>
                            </Col>
                            <Col xs={10} className="Text-Misi">
                            <p>Mengarahkan Anak Menjadi Pribadi Mandiri yang Disiplin, Peka  Sosial dan Berakhlakul Karimah.</p>
                            </Col>
                        </Row>
                        <Row className="Line-Misi">
                            <Col xs={2} className="Img-Misi">
                            <img src={IconMisi3} alt="icon-misi"/>
                            </Col>
                            <Col xs={10} className="Text-Misi">
                            <p>Melaksanakan Pendidikan yang Mengembangkan Potensi Anak.</p>
                            </Col>
                        </Row>
                    </Row>
                    
                </div>
            </section>
        </div>
    )
}
