import React from 'react'
import { Row, Col } from 'react-bootstrap'
import './Kontak.css'
import IconPin from 'assets/images/pin.png'
import IconInstagram from 'assets/images/instagram.png'
import IconPhone from 'assets/images/phone.png'
import IconWeb from 'assets/images/web.png'

export default function Kontak() {
    return (
        <div>
            <section className="Container-Kontak">
                <Row className="Title-Kontak">
                    <h1>Kontak Kami</h1>
                    <hr/>
                </Row>

                <Row className="Kontak-Tk">
                    <Col md={6}className="Tkit-1-2-Kontak">
                        <h1>TKIT 1</h1>
                        <div className="Card Card-Kontak">
                            <div className="Card-Body">
                                <h4 className="Card-Title">TKIT Al - Istiqomah 1</h4>
                                <a href="https://goo.gl/maps/C7UawLzALeH6bnSV7" target="blank_"><p className="Card-Text"> <img src={IconPin} alt="Icon-Pin" className="Icon-Pin"/>
                                Jl. Nanas Raya No 7 Perumnas 1 </p> 
                                <p className="p-bottom">Kota Tangerang</p> </a>
                                <a href="https://www.instagram.com/tkit_alistiqomah/" target="blank_"><p className="Card-Text"> <img src={IconInstagram} alt="Icon-Ig" className="Icon-Ig"/>
                                @tkit_alistiqomah </p></a>
                                <p className="Card-Text"> <img src={IconPhone} alt="Icon-Ig" className="Icon-Ig"/>
                                021 - 553 8227 </p>
                                
                            </div>
                        </div>
                    </Col>

                    <Col md={6}className="Tkit-1-2-Kontak">
                        <h1>TKIT 2</h1>
                        <div className="Card Card-Kontak">
                            <div className="Card-Body">
                                <h4 className="Card-Title">TKIT Al - Istiqomah 2</h4>
                                <a href="https://goo.gl/maps/p8noMEo41qxyjcbF9" target="blank_"><p className="Card-Text"> <img src={IconPin} alt="Icon-Pin" className="Icon-Pin"/>
                                Jl. Mpu Tantular Raya No 10B </p>
                                <p className="p-bottom">Perumnas II Kab Tangerang</p></a>
                                <a href="https://www.instagram.com/tkit2_istiqomah/" target="blank_"><p className="Card-Text"> <img src={IconInstagram} alt="Icon-Ig" className="Icon-Ig"/>
                                @tkit2_istiqomah </p></a>
                                <p className="Card-Text"> <img src={IconPhone} alt="Icon-Ig" className="Icon-Ig"/>
                                021 - 553 8227 </p>
                            </div>
                        </div>

                    </Col>
                </Row>

                <Row className="Yayasan-Kontak pt-5">
                    <h1>Yayasan Pendidikan Insan Istiqomah</h1>
                </Row>

                <Row className="Kontak-Yayasan">
                    <Col>
                    <div className="Card Card-Yayasan">
                            <div className="Card-Body">
                                <a href="https://g.page/al-istiqomah?share" target="blank_"><p className="Card-Text"> <img src={IconPin} alt="Icon-Pin" className="Icon-Pin"/>
                                Jl. Sawo Raya Perumnas 1 Kota Tangerang, <span className="p-kurikulum-sm">Banten 15810</span> </p></a>
                                <a href="https://www.instagram.com/istiqomah_sch/" target="blank_"><p className="Card-Text"> <img src={IconInstagram} alt="Icon-Ig" className="Icon-Ig"/>
                                @istiqomah_sch </p></a>
                                <p className="Card-Text"> <img src={IconPhone} alt="Icon-Ig" className="Icon-Ig"/>
                                021 - 553 8227 </p>
                                <a href="https://istiqomah.sch.id/" target="blank_"><p className="Card-Text"> <img src={IconWeb} alt="Icon-Ig" className="Icon-Ig"/>
                                www.istiqomah.sch.id</p></a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </section>
        </div>
    )
}
