import React from 'react'
import {Row, Col, Figure} from 'react-bootstrap'
import ImageKegiatan1 from 'assets/images/kegiatan/1.jpg'
import ImageKegiatan2 from 'assets/images/kegiatan/5.jpg'
import ImageKegiatan3 from 'assets/images/kegiatan/6.jpg'
import ImageKegiatan4 from 'assets/images/kegiatan/7.jpg'
import { Link } from 'react-router-dom'
import '../CSSTKIT/Kegiatan.css'
import { Fade } from 'react-reveal'


export default function KegiatanTkit1() {
    return (
        <div>
            <section className="Container-Kegiatan">
                <Row className="Title-Kegiatan">
                    <h1> Kegiatan Tkit Al - Istiqomah 1</h1>
                </Row>

                <Row className="Kelas-A">
                    <h3> Kelas A</h3>
                    <Fade>
                    <hr/>
                    </Fade>
                </Row>

                <div className="Foto-Kegiatan">
                    <Row>
                        <Col md= {6}>
                        <Figure className="Kegiatan-Img">
                            <a href="">
                                <Figure.Image src={ImageKegiatan1} alt="image-fasilitas"/>
                                <span> Kegiatan A</span>
                            </a>
                        </Figure>
                        </Col>
                        <Col md= {6}>
                        <Figure className="Kegiatan-Img">
                            <a href="">
                                <Figure.Image src={ImageKegiatan2} alt="image-fasilitas"/>
                                <span> Kegiatan B</span>
                            </a>
                        </Figure>
                        </Col>
                        <Col md= {6}>
                        <Figure className="Kegiatan-Img">
                            <a href="">
                                <Figure.Image src={ImageKegiatan3} alt="image-fasilitas"/>
                                <span> Kegiatan C</span>
                            </a>
                        </Figure>
                        </Col>
                        <Col md= {6}>
                        <Figure className="Kegiatan-Img">
                            <a href="">
                                <Figure.Image src={ImageKegiatan4} alt="image-fasilitas"/>
                                <span> Kegiatan D</span>
                            </a>
                        </Figure>
                        </Col>
                    </Row>
                </div>
                <Link to="/tkit-2" className="seemore-photo" hasShadow>
                                See More
                </Link>


                <Row className="Kelas-B">
                    <h3> Kelas B</h3>
                    <Fade>
                    <hr/>
                    </Fade>
                </Row>

                <div className="Foto-Kegiatan">
                    <Row>
                        <Col md= {6}>
                        <Figure className="Kegiatan-Img">
                            <a href="">
                                <Figure.Image src={ImageKegiatan1} alt="image-fasilitas"/>
                                <span> Kegiatan A</span>
                            </a>
                        </Figure>
                        </Col>
                        <Col md= {6}>
                        <Figure className="Kegiatan-Img">
                            <a href="">
                                <Figure.Image src={ImageKegiatan2} alt="image-fasilitas"/>
                                <span> Kegiatan B</span>
                            </a>
                        </Figure>
                        </Col>
                        <Col md= {6}>
                        <Figure className="Kegiatan-Img">
                            <a href="">
                                <Figure.Image src={ImageKegiatan3} alt="image-fasilitas"/>
                                <span> Kegiatan C</span>
                            </a>
                        </Figure>
                        </Col>
                        <Col md= {6}>
                        <Figure className="Kegiatan-Img">
                            <a href="">
                                <Figure.Image src={ImageKegiatan4} alt="image-fasilitas"/>
                                <span> Kegiatan D</span>
                            </a>
                        </Figure>
                        </Col>
                    </Row>
                </div>
                <Link to="/tkit-2" className="seemore-photo " hasShadow>
                                See More
                    </Link>
            </section>
        </div>
    )
}
