import React from 'react'
import { Component } from 'react'
import Footer from 'parts/Footer/Footer'
import Kurikulum from 'parts/Kurikulum/Kurikulum'

export default class KurikulumPages extends Component {
    render() {
    return (
        <>
            <Kurikulum />
            <Footer />
        </>
        )
    }
}
