import React, { useState } from 'react'
import LogoBrand from 'assets/images/icon-logo.png'
import './Navbar.css'
import { Link } from 'react-router-dom'
import Dropdown from './Dropdown'

function Navbar() {
    const [click, setClick] = useState(false);
    const [dropdown, setDropdrown] = useState(false);
    
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false)
    
    const onMouseEnter = () => {
        if (window.innerWidth < 960) {
          setDropdrown(false);
        } else {
          setDropdrown(true);
        }
      };
    
      const onMouseLeave = () => {
        if (window.innerWidth < 960) {
          setDropdrown(false);
        } else {
          setDropdrown(false);
        }
      };

        return (
            <>
                <nav className="NavbarItems">
                    <Link to="/" className='Logo-Navbar'>
                        <img src={LogoBrand} className="LogoBrand" alt="Logo-Istiqomah"/>
                        <h1> TKIT AL - ISTIQOMAH</h1>
                    </Link>
                    <div className = "menu-icon" onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'}>
                        </i>
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <Link to="/" className='nav-links' onClick={closeMobileMenu}>
                                Home
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to="/profile" className='nav-links' onClick={closeMobileMenu}>
                                Profile
                            </Link>
                        </li>
                        <li className='nav-item akademik' 
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave} 
                        >
                            <Link to="/akademik" className='nav-links' onClick={closeMobileMenu}>
                                Akademik <i className='fas fa-caret-down' />
                            </Link>
                            {dropdown && <Dropdown />}
                        </li>
                        <li className='nav-item'>
                            <Link to="/kurikulum" className='nav-links' onClick={closeMobileMenu}>
                                Kurikulum
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to="/kontak" className='nav-links' onClick={closeMobileMenu}>
                                Kontak
                            </Link>
                        </li>
                         <li className='nav-item '>
                            <a href="https://ppdb.istiqomah.sch.id/" target="blank_" className='nav-links ppdb' onClick={closeMobileMenu}>
                                PPDB
                            </a>
                        </li> 
                    </ul>
                </nav>
        </>
    )
}


export default Navbar;