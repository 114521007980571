import React from 'react'
import {Col, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import { Fade } from 'react-reveal'
import ImageTkit1 from 'assets/images/homepage/tkit-1.svg'
import ImageTkit1Md from 'assets/images/homepage/tkit-1-md.svg'
import ImageTkit1Sm from 'assets/images/homepage/tkit-1-sm.svg'
import './Tkit.css'

export default function Tkit1() {
    return (
        <div>
            <section className="Container-tkit pt-4">
                <Row className="Tkit-1">
                    <Col md={4} className="Tkit-img Img-lg-device">
                        <img src={ImageTkit1} alt="TKIT-1" />
                    </Col>
                    <Col md={4} className="Tkit-img Img-md-device">
                        <img src={ImageTkit1Md} alt="TKIT-1" />
                    </Col>
                    <Col md={8} className="Tkit-text " >
                        <h1>TKIT Al - Istiqomah 1</h1>
                        <Fade>
                        <hr />
                        </Fade>
                        <p>TKIT Al - Istiqomah 1 didirikan pada tahun 1981 dibawah naungan Yayasan Pendidikan Insan Istiqomah, 
                            yang berada di Jalan Nanas Raya No. 7 Perumnas 1 Kelurahan Cibodasari Kecamatan Cibodas 
                            Kota Tangerang. Meskipun sudah 40 tahun berdiri, TKIT Al - Istiqomah 1 masih dipercaya oleh 
                            masyarakat sampai dengan sekarang, tentunya hal ini karena TKIT Al - Istiqomah 1 selalu berinovasi 
                            dalam pembelajaran, dengan menyesuaikan perkembangan zaman tanpa meninggalkan karakter dan 
                            nilai-nilai akidah islami.
                            Dengan izin Allah SWT pada tahun 2019, TKIT Al Istiqomah 1  mendapatkan Akreditasi A (Unggul) dan 
                            pada tahun 2021 TKIT Al - Istiqomah menjadi sample dari Kementrian Pendidikan dan Kebudayaan menjadi salah 
                            satu sekolah Penjaminan Mutu dan seleksi sekolah Penggerak Angkatan 2.</p>
                        <Row>
                            <Link to="/tkit-1" className="btn btn-tkit Btn-md-device" hasShadow>
                                See More
                            </Link>
                        </Row>
                       
                    </Col>
                    <Col className="Tkit-img Img-small-device">
                        <img src={ImageTkit1Sm} alt="TKIT-1" />
                    </Col>
                    <Row>
                            <Link to="/tkit-1" className="btn btn-tkit Btn-small-device" hasShadow>
                                See More
                            </Link>
                    </Row>
                </Row>
            </section>
        </div>
    )
}
