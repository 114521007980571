import React, { Fragment } from 'react';
import { ReactDOM } from 'react';
import { BrowserRouter,  Route, Switch} from 'react-router-dom';
import 'App.css'
import LandingPages from 'pages/LandingPages';
import ProfilePage from 'pages/ProfilePage';
import Navbar from 'parts/Navbar/Navbar';
import KurikulumPage from 'pages/KurukulumPage'
import KontakPage from 'pages/KontakPage';
import AkademikPage from 'pages/AkademikPage';
import Tkit1ProfilePage from 'pages/Tkit1ProfilePage'
import Tkit1KegiatanPage from 'pages/Tkit1KegiatanPage'
import Tkit2ProfilePage from 'pages/Tkit2ProfilePage';
import ScrollToTop from 'pages/ScrollToTop';


function App() {
  
  return (
    <div className="App">
      <>
      <BrowserRouter>
        <ScrollToTop />
          <Navbar />
            <Switch>
            <Route path="/" exact component={LandingPages} />
            <Route path="/profile" component={ProfilePage}/>
            <Route path="/akademik" component={AkademikPage}/>
            <Route path="/kurikulum" component={KurikulumPage} />
            <Route path="/kontak" component={KontakPage}/>
            <Route path="/tkit-1" component={Tkit1ProfilePage}/>
            <Route path="/profile-tkit1" component={Tkit1ProfilePage}/>
            <Route path="/kegiatan-tkit1" component={Tkit1KegiatanPage}/>
            <Route path="/tkit-2" component={Tkit2ProfilePage}/>
            <Route path="/profile-tkit2" component={Tkit2ProfilePage}/>
            </Switch>
        
      </BrowserRouter>
      </>
    </div>
  );
}

export default App;
