import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { Fade } from 'react-reveal'
import './Footer.css'

export default function Footer() {
    return (
        <div> 
            <section className="Container-Footer "> 
                <Row className="Footer">
                    <Col md={4} className="YPI">
                        <h1>Yayasan Pendidikan Insan</h1>
                        <h1>Istiqomah</h1>
                        <p>&copy;Copyright 2021</p>
                    </Col>

                    <Col md={3} className="Website">
                        <h1>Website</h1>
                        <Fade>
                        <hr />
                        </Fade>
                        <p> <a href="https://istiqomah.sch.id/" target="blank_">YPI Istiqomah</a> </p>
                        <p> <a>MI Al - Istiqomah</a> </p>
                        <p> <a>SDIT Al - Istiqomah</a> </p>
                        <p> <a>TKIT Al - Istiqomah</a> </p>
                    </Col>

                    <Col md={2} className="Others">
                        <Link to="/profile" className="link-footer"><h1>Profile</h1></Link>
                        <Link to="/akademik" className="link-footer"><h1>Akademik</h1></Link>
                        <Link to="/kurikulum" className="link-footer"><h1>Kurikulum</h1></Link>
                        <Link to="/kontak" className="link-footer"><h1>Kontak</h1></Link>
                    </Col>

                    <Col md={3} className="Office">
                        <h1>Office YPI Istiqomah</h1>
                        <p>Jl. Sawo Raya No. 1, Perumnas 1 Karawaci Tangerang 15810</p>
                    </Col>

                </Row>
            </section>
        </div>
    )
}
