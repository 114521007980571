export const MenuItems = [
    {
        title: 'Home',
        url:'/',
        cName: 'nav-links'
    },
    {
        title: 'Profil',
        url:'/profile',
        cName: 'nav-links'
    },
    {
        title: 'Akademik',
        url:'#',
        cName: 'nav-links'
    },
    {
        title: 'Kurikulum',
        url:'/kurikulum',
        cName: 'nav-links'
    },
    {
        title: 'Kontak Kami',
        url:'/kontak',
        cName: 'nav-links'
    }

]

export const MenuDropdown = [
    {
      id: 1,
      title: "Tkit Al - Istiqomah 1",
      path: "/tkit-1",
      cName: "menu-dropdown dropdown-top",
    },
    {
      id: 2,
      title: "Tkit Al - Istiqomah 2",
      path: "/tkit-2",
      cName: "menu-dropdown",
    }
  ];
  