import React from 'react'
import {Row, Col} from 'react-bootstrap'
import '../CSSTKIT/StaffPengajar.css'
import Pengajar1 from '../../../assets/images/profile-tkit-2/bu-nurul.png'
import Pengajar2 from '../../../assets/images/profile-tkit-2/bu-fauziah.png'
import Pengajar3 from '../../../assets/images/profile-tkit-2/bu-leni.png'
import Pengajar4 from '../../../assets/images/profile-tkit-2/bu-syela.png'
import Staff1 from '../../../assets/images/profile-tkit-2/bu-castem.png'
import Staff2 from '../../../assets/images/profile-tkit-2/bu-mala.png'

export default function StaffPengajar() {
    return (
        <div>
            <section className="Container-StaffPengajar">
                <Row className="Title-StaffPengajar">
                    <h1>Staff & Pengajar</h1>
                    <hr/>
                </Row>

                <Row className="StaffPengajar justify-content-center pb-2">
                    <Col xs={6} md={4}>
                        <div className="Card Card-StaffPengajar">
                           <img src={Pengajar1} className="Card-Img" Alt="bu-nurul"/>
                            <div className="Card-Body StaffPengajar-Text">
                                <h5 className="Card-Title">Nurul Hikmah, S.Pd.I</h5>
                                <p className="Card-Text">Guru TK A </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={4}>
                        <div className="Card Card-StaffPengajar">
                            <img src={Pengajar2} className="Card-Img" />
                            <div className="Card-Body StaffPengajar-Text">
                                <h5 className="Card-Title">Fauzia, S.Pd</h5>
                                <p className="Card-Text">Guru TK B </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={4}>
                        <div className="Card Card-StaffPengajar">
                            <img src={Pengajar3} className="Card-Img" />
                            <div className="Card-Body StaffPengajar-Text">
                                <h5 className="Card-Title">Leni Mindawati</h5>
                                <p className="Card-Text">Guru TK B </p>
                            </div>
                        </div>
                    </Col>
                

                    <Col xs={6} md={4}>
                        <div className="Card Card-StaffPengajar">
                            <img src={Pengajar4} className="Card-Img" />
                            <div className="Card-Body StaffPengajar-Text">
                                <h5 className="Card-Title">Seyla Chairunnisa, S.Pd</h5>
                                <p className="Card-Text">Guru TK B</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={4}>
                        <div className="Card Card-StaffPengajar">
                            <img src={Staff1} className="Card-Img" />
                            <div className="Card-Body StaffPengajar-Text">
                                <h5 className="Card-Title">Bu Castem</h5>
                                <p className="Card-Text">Staff ART </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={4}>
                        <div className="Card Card-StaffPengajar">
                            <img src={Staff2} className="Card-Img" />
                            <div className="Card-Body StaffPengajar-Text">
                                <h5 className="Card-Title">Bu Mala</h5>
                                <p className="Card-Text">Staff TU </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </section>
        </div>
    )
}
