import React from 'react'
import {Row, Col} from 'react-bootstrap'
import '../CSSTKIT/KepalaSekolah.css'
import KepalaSekolah from '../../../assets/images/profile-tkit-1/bu-ani.png'

export default function Tkit1KepalaSekolah() {
    return (
        <div>
            <section className="Container-KepalaSekolah">
                <Row className="Title-Akademik">
                    <h1> TKIT AL - ISTIQOMAH 1 </h1>
                    <hr />
                </Row>

                <Row className="KepalaSekolah">

                    <Col lg={3} md={4} className="KepalaSekolah-Img">
                        <img src={KepalaSekolah}/>
                    </Col>

                    <Col lg={9} md={8} className="KepalaSekolah-Text">
                        <h1>Wahyu Agus Setiani, S.Pd </h1>
                        <h3>Kepala Sekolah</h3>
                        <p> Assalamu’alaikum Wr.Wb</p>
                        <p> Alhamdulillah, Puji Syukur kami panjatkan kehadirat Allah SWT atas izinNya, di usia 40 tahun dan di masa pandemi covid-19, 
                            TKIT Al Istiqomah 1 masih dipercaya oleh masyarakat. Tentu hal ini dikarenakan keikhlasan dan semangat berinovasi terus 
                            menerus dalam pembelajaran dari pendidik dan tenaga kependidikan TKIT Al Istiqomah 1, serta dorongan dan motivasi dari 
                            Yayasan Pendidikan Insan Istiqomah. </p>
                    </Col>
                    <p className="paragraf-2">Dan Alhamdulillah dengan izin Allah SWT TKIT Al Istiqomah 1 mendapatkan 
                            akreditasi A ( unggul ) dan mendapatkan kepercayaan dari pemerintah dalam beberapa kegiatan. 
                            Harapan kami semoga sampai kapanpun TKIT Al Istiqomah 1 dapat menebarkan kebaikan dan manfaat dalam pendidikan 
                            khususnya Pendidikan Anak Usia Dini serta selalu dipercaya oleh masyarakat dengan inovasi-inovasi pembelajarannya
                            yang selalu mengikuti zaman tanpa meninggalkan karakter dan nilai-nilai akidah Islamiyah.</p>
                    <p className="paragraf-2"> Wassalamu’alaikum Wr.Wb </p>
                </Row>
            </section>
        </div>
    )
}
