import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Icon1 from 'assets/images/kegiatan-tahunan/hajj.png'
import Icon2 from 'assets/images/kegiatan-tahunan/cooking.png'
import Icon3 from 'assets/images/kegiatan-tahunan/sains.png'
import Icon4 from 'assets/images/kegiatan-tahunan/market.png'
import Icon5 from 'assets/images/kegiatan-tahunan/indonesia.png'
import Icon6 from 'assets/images/kegiatan-tahunan/art.png'
import Icon7 from 'assets/images/kegiatan-tahunan/tema.png'
import Icon8 from 'assets/images/kegiatan-tahunan/trip.png'
import '../CSSTKIT/KegiatanTahunan.css'

export default function KegiatanTahunanTkit2() {
    return (
        <div>
            <section className="Container-KegiatanTahunan">
                <Row className="KegiatanTahunan">
                        <h1>Kegiatan Tahunan</h1>
                        <hr/>
                </Row>

                <Row className="KegiatanTahunan-Content justify-content-center pb-2">
                    <Col xs={6} md={3}>
                        <div className="Card Card-Kegiatan Tahunan">
                            <img src={Icon1} className="Card-Img" />
                            <div className="Card-Body KegiatanTahunan-Text">
                                <h5 className="Card-Title">Manasik Haji</h5>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="Card Card-Kegiatan Tahunan">
                            <img src={Icon2} className="Card-Img" />
                            <div className="Card-Body KegiatanTahunan-Text">
                                <h5 className="Card-Title">Cooking Class</h5>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="Card Card-Kegiatan Tahunan">
                            <img src={Icon3} className="Card-Img" />
                            <div className="Card-Body KegiatanTahunan-Text">
                                <h5 className="Card-Title">Gebyar Sains</h5>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="Card Card-Kegiatan Tahunan">
                            <img src={Icon4} className="Card-Img" />
                            <div className="Card-Body KegiatanTahunan-Text">
                                <h5 className="Card-Title">Market Day</h5>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="Card Card-Kegiatan Tahunan">
                            <img src={Icon5} className="Card-Img" />
                            <div className="Card-Body KegiatanTahunan-Text">
                                <h5 className="Card-Title">National Day</h5>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="Card Card-Kegiatan Tahunan">
                            <img src={Icon6} className="Card-Img" />
                            <div className="Card-Body KegiatanTahunan-Text">
                                <h5 className="Card-Title">Seni</h5>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="Card Card-Kegiatan Tahunan">
                            <img src={Icon7} className="Card-Img" />
                            <div className="Card-Body KegiatanTahunan-Text">
                                <h5 className="Card-Title">Puncak Tema</h5>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="Card Card-Kegiatan Tahunan">
                            <img src={Icon8} className="Card-Img" />
                            <div className="Card-Body KegiatanTahunan-Text">
                                <h5 className="Card-Title">Outing Class</h5>
                            </div>
                        </div>
                    </Col>
                </Row>
                </section>
        </div>
    )
}
