import React from 'react'
import {Row, Col} from 'react-bootstrap'
import { Fade } from 'react-reveal'
import ImageSentra1 from '../../assets/images/sentra/shalat.png'
import ImageSentra2 from '../../assets/images/sentra/persiapan.png'
import ImageSentra3 from '../../assets/images/sentra/bahan-alam.png'
import ImageSentra4 from '../../assets/images/sentra/main-peran.png'
import './Sentra.css'

export default function Sentra() {
    return (
        <div>
            <section className="Container-Sentra pt-3">
                <div className="Title">
                    <h2>Pembelajaran Sentra</h2>    
                </div>
                <div className="Subtitle-Sentra">
                    <p>Adalah model pembelajaran yang menekankan pada dukungan minat, potensi, dan kekuatan anak dari pengembangan ide hingga tuntas menyelesaikan hasil karyanya melalui pijakan dan kesepakatan antara guru dan anak.</p>    
                </div>
        
                <div className="Sentra">
                    <Row className="Sentra-Card justify-content-center pb-2">
                        <Col xs={12} sm={6} md={3}>
                            <Fade delay={100}>
                            <div className="card">
                                <img src={ImageSentra1} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 className="Card-Title">Sentra Imtaq</h5>
                                    <hr/>
                                    <p class="card-text">Disediakan sarana ibadah dan aturan dalam beribadah. Misal mengajarkan doa sehari-hari, praktek shalat dan praktek wudhu.</p>
                                </div>
                            </div>
                            </Fade>
                        </Col>
                        
                        <Col xs={12} sm={6} md={3}>
                            <Fade delay={200}>
                            <div className="card card-xs">
                                <img src={ImageSentra2} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 className="Card-Title">Sentra Persiapan</h5>
                                    <hr/>
                                    <p class="card-text">Pusat kegiatan main untuk mempersiapkan anak mengenal tullisan, huruf dan berhitung.</p>
                                </div>
                            </div>
                            </Fade>
                        </Col>
                        <Col xs={12} sm={6} md={3}>
                            <Fade delay={300}>
                            <div className="card card-sm">
                                <img src={ImageSentra3} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 className="Card-Title">Sentra Bahan Alam</h5>
                                    <hr/>
                                    <p class="card-text">Memberikan pengalaman anak untuk mengenal dan mengeksplorasi yang berkaitan dengan benda-benda yang ada di sekitarnya.</p>
                                </div>
                            </div>
                            </Fade>
                        </Col>
                        <Col xs={12} sm={6} md={3}>
                            <Fade delay={400}>
                            <div className="card card-sm">
                                <img src={ImageSentra4} class="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 className="Card-Title">Sentra Main Peran</h5>
                                    <hr/>
                                    <p class="card-text">Kegiatan memerankan tokoh-tokoh tertentu dengan menggunakan alat bantu yang sesuai dengan peran yang ditokohkan.</p>
                                    
                                </div>
                            </div>
                            </Fade>
                        </Col>
                    </Row>

            
                </div>

                
            </section>
        </div>
    )
}
