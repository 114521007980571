import React from 'react'
import { Row, Col} from 'react-bootstrap'
import IconMisi1 from 'assets/images/misi-yayasan/1.png'
import IconMisi2 from  'assets/images/misi-yayasan/2.png'
import IconMisi3 from 'assets/images/misi-yayasan/3.png'
import IconMisi4 from 'assets/images/misi-yayasan/4.png'
import IconMisi5 from 'assets/images/misi-yayasan/5.png'


import './VisiMisi.css';


export default function VisiMisi(props) {
    return (
        <div>
            <section className="Container-VisiMisi">
                <Row className="Visi">
                        <h1> Visi </h1>
                        <hr /> 
                            <p>
                            Unggul dalam prestasi berdasarkan IMTAQ.
                            </p>
                </Row>
                <Row className="Misi">
                    <h1> Misi </h1>
                    <hr />
                </Row>
                <div className="Misi">
                    
                    <Row className="Misi-Container">
                        <Row className="Line-Misi Line-1">
                            <Col xs={2} className="Img-Misi">
                            <img src={IconMisi1} alt="icon-misi"/>
                            </Col>
                            <Col xs={10} className="Text-Misi">
                            <p>Mewujudkan sekolah Al Istiqomah sebagai sekolah unggulan.</p>
                            </Col>
                        </Row>
                        <Row className="Line-Misi ">
                            <Col xs={2} className="Img-Misi">
                            <img src={IconMisi2} alt="icon-misi"/>
                            </Col>
                            <Col xs={10} className="Text-Misi">
                            <p>Mewujudkan pendidikan seimbang antara IMTAQ dan IPTEK.</p>
                            </Col>
                        </Row>
                        <Row className="Line-Misi">
                            <Col xs={2} className="Img-Misi">
                            <img src={IconMisi3} alt="icon-misi"/>
                            </Col>
                            <Col xs={10} className="Text-Misi">
                            <p>Menyebarluaskan pendidikan berkualitas yang dijiwai nilai-nilai Islam.</p>
                            </Col>
                        </Row>
                        <Row className="Line-Misi">
                            <Col xs={2} className="Img-Misi">
                            <img src={IconMisi4} alt="icon-misi"/>
                            </Col>
                            <Col xs={10} className="Text-Misi">
                            <p>Mengembangkan bakat dan kreativitas siswa agar berilmu pengetahuan dan berakhlak mulia.</p>
                            </Col>
                        </Row>
                        <Row className="Line-Misi">
                            <Col xs={2} className="Img-Misi">
                            <img src={IconMisi5} alt="icon-misi"/>
                            </Col>
                            <Col xs={10} className="Text-Misi">
                            <p>Melahirkan guru berkualitas yang islami dan kreatif untuk menunjang penyebarluasan ilmu pengetahuan.</p>
                            </Col>
                        </Row>
                    </Row>
                    
                </div>
            </section>
        </div>
    )
}
