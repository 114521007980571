import React from 'react'
import Hero from 'parts/Hero/Hero'
import Tkit1 from 'parts/Tkit/Tkit1'
import Tkit2 from 'parts/Tkit/Tkit2'
import Fasilitas from 'parts/Fasilitas/Fasilitas'
import Sentra from 'parts/Sentra/Sentra'
import Choose from 'parts/Choose/Choose'
import Level from 'parts/Level/Level'
import Quotes from 'parts/Quotes/Quotes'
import Footer from 'parts/Footer/Footer'
import { Component } from 'react'

export default class LandingPages extends Component {
    render() {
    return (
        <>
            
            <Hero />
            <Tkit1 />
            <Tkit2 />
            <Fasilitas />
            <Sentra />
            <Choose />
            <Level />
            <Quotes />
            <Footer />
        </>
        )
    }
}
