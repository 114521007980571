import React from 'react'
import {Row, Col} from 'react-bootstrap'
import { Fade } from 'react-reveal'
import LevelA from 'assets/images/level/tk-a.png'
import LevelB from 'assets/images/level/tk-b.png'
import './Level.css'

export default function Level() {
    return (
        <div>
            <section className="Container-Level pb-5">
            <div className="Title-Level pb-3">
                    <h2>Jenjang Pendidikan</h2>
            </div>
                <Row className="Level-Card justify-content-center">
                    
                    <Col xs={6} md={6}>
                        <Fade delay={100}>
                        <div className="Card">
                            <img src={LevelA} className="Card-img-top" alt="Tkit-A"/>
                            <div className="Card-Body">
                                <h5 className="Card-Title">TK A</h5>
                                <p className="Card-Text">Usia 3.8-5 Tahun <br /> Masa belajar 2 semester </p>
                            </div>
                        </div>
                        </Fade>
                    </Col>
                    <Col xs={6} md={6}>
                        <Fade delay={300}>
                        <div className="Card">
                            <img src={LevelB} className="Card-img-top" alt="Tkit-B"/>
                            <div className="Card-Body">
                                <h5 className="Card-Title">TK B</h5>
                                <p className="Card-Text">Usia 5-6 Tahun <br /> Masa belajar 2 semester </p>
                            </div>
                        </div>
                        </Fade>
                    </Col>
                    
                </Row>
            </section>
        </div>
    )
}
