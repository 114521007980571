import React from 'react'
import {Row, Col} from 'react-bootstrap'
import '../CSSTKIT/KepalaSekolah.css'
import KepalaSekolah from '../../../assets/images/profile-tkit-2/bu-rifa.png'

export default function Tkit2KepalaSekolah() {
    return (
        <div>
            <section className="Container-KepalaSekolah">
                <Row className="Title-Akademik">
                    <h1> TKIT AL - ISTIQOMAH 2 </h1>
                    <hr />
                </Row>

                <Row className="KepalaSekolah">
                    <Col lg={3} md={4} className="KepalaSekolah-Img">
                        <img src={KepalaSekolah} className="Img-Kepsek"/>
                    </Col>

                    <Col lg={9} md={8} className="KepalaSekolah-Text">
                        <h1>Siti Rifatul Hamidah, S.Pd </h1>
                        <h3>Kepala Sekolah</h3>
                        <p> Assalamualaikum Wr.Wb</p>
                        <p> Alhamdulillah puji syukur kami panjatkan kehadirat Allah SWT berkat Rahmat dan Karunia-Nya TKIT Al Istiqomah 2 
                            selalu Istiqomah dalam menyelenggarakan pendidikan anak usia dini. Di masa pandemi covid-19 TKIT Al Istiqomah 2 
                            berusaha memberikan pelayanan yang terbaik kepada siswa dengan memenuhi hak anak 
                            untuk mendapatkan pembelajaran dan memastikan siswa belajar.</p>
                    </Col>

                    <p className="paragraf-2 p-2-tkit-2"> Metode Pembelajaran Jarak Jauh melalui daring di kelas Zoom maupun google 
                            meet diharapkan dapat digunakan sebagai bagian yang tidak terpisahkan dalam rangka memberikan stimulasi yang optimal terhadap 
                            capaian perkembangan siswa. 
                            <br/>
                                <br/>
                       Dalam pelaksanaan kegiatan harian di TKIT Al Istiqomah 2, mengadaptasi pendekatan pembelajaran sentra yang kami kembangkan kembali 
                                sehingga dapat menstimulus perkembangan anak, meningkatkan life skill dengan tetap mengedepankan pendidikan karakter berdasarkan 
                                nilai2 Al Quran. Proses pembelajaran berpusat pada anak dimana guru hanya sebagai motivator dan fasilitator. Setiap kegiatan main kami 
                                rancang sehingga anak dapat belajar secara aktif, kreatif dan inovatif.
                                <br/>
                                <br/>
                        Terima kasih kami ucapkan kepada Ayah Bunda yang telah mempercayakan ananda sekolah di TKIT Al Istiqomah 2. Semoga TKIT Al Istiqomah 2 bisa menjadi 
                                partner terbaik bagi ayah bunda dalam membimbing, mendampingi, dan memfasilitasi putra putri dalam menstimulus tumbuh kembang ananda.</p>
                            <p className="paragraf-2">Wassalamu'alaikum,wr.wb</p>
                </Row>
            </section>
        </div>
    )
}
