import React from 'react'
import { Component } from 'react'
import Tkit1KepalaSekolah from 'parts/Akademik/TKIT1/Tkit1KepalaSekolah'
import VisiMisiTkit1 from 'parts/Akademik/TKIT1/VisiMisiTkit1'
import Tujuan from 'parts/Akademik/TKIT1/Tujuan'
import KegiatanTahunan from 'parts/Akademik/TKIT1/KegiatanTahunan'
import Ekskul from 'parts/Akademik/TKIT1/Ekskul'
import StaffPengajar from 'parts/Akademik/TKIT1/StaffPengajar'
import Footer from 'parts/Footer/Footer'

export default class Tkit1ProfilePage extends Component {
    render() {
    return (
        <>
           <Tkit1KepalaSekolah />
           <VisiMisiTkit1/>
           <Tujuan />
           <KegiatanTahunan />
           <Ekskul />
           <StaffPengajar />
           <Footer />
        </>
        )
    }
}
