import React from 'react'
import { Component } from 'react'
import Yayasan from 'parts/Yayasan/Yayasan'
import VisiMisi from 'parts/VisiMisi/VisiMisi'
import Sejarah from 'parts/Sejarah/Sejarah'
import Footer from 'parts/Footer/Footer'

export default class ProfilePage extends Component {
    render() {
    return (
        <>
            <Yayasan />
            <VisiMisi />
            <Sejarah />
            <Footer />
        </>
        )
    }
}
