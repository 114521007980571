import React from 'react'
import { Component } from 'react'
import Kontak from 'parts/Kontak/Kontak'
import Footer from 'parts/Footer/Footer'

export default class KontakPage extends Component {
    render() {
    return (
        <>
            <Kontak />
            <Footer />
        </>
        )
    }
}
