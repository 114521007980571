import React from 'react'
import {Col, Row} from 'react-bootstrap'
import ImageTkit2 from 'assets/images/homepage/tkit-2.svg'
import ImageTkit2Md from 'assets/images/homepage/tkit-2-md.svg'
import ImageTkit2Sm from 'assets/images/homepage/tkit-2-sm.svg'
import { Fade } from 'react-reveal'
import './Tkit.css'
import { Link } from 'react-router-dom'

export default function Tkit2() {
    return (
        <div>
            <section className="Container-tkit ">
                <Row className="Tkit-2">
                    <Col md={8} className="Tkit-text tkit-text-2">
                        <h1>TKIT Al - Istiqomah 2</h1>
                        <Fade>
                        <hr />
                        </Fade>
                        <p>TKIT Al - Istiqomah 2 merupakan sekolah islam yang menanamkan karakter dasar islami sejak usia dini 
                           melalui pembiasaan-pembiasaan harian dengan melatih kemandirian  serta mengenalkan pembelajaran 
                           Al Qur'an dengan cara menyenangkan.</p>
                        <Row>
                            <Link to="/tkit-2" className="btn btn-tkit-2 Btn-md-device" hasShadow>
                                    See More
                            </Link>
                        </Row>
                        
                    </Col>
                    <Col md={4} className="Tkit-img Tkit-img-2 Img-lg-device">
                        <img src={ImageTkit2} alt="TKIT-1" />
                    </Col>
                    <Col md={4} className="Tkit-img Tkit-img-2 Img-md-device">
                        <img src={ImageTkit2Md} alt="TKIT-1" />
                    </Col>
                    
                    <Col className="Tkit-img Img-small-device">
                        <img src={ImageTkit2Sm} alt="TKIT-1" />
                    </Col>
                    <Row>
                            <Link to="/tkit-2" className="btn btn-tkit Btn-small-device" hasShadow>
                                See More
                            </Link>
                    </Row>
                </Row>
            </section>
        </div>
    )
}
