import React from 'react'
import { Component } from 'react'
import KegiatanTkit1 from 'parts/Akademik/TKIT1/KegiatanTkit1'
import Footer from 'parts/Footer/Footer'

export default class Tkit1KegiatanPage extends Component {
    render() {
    return (
        <>
            <KegiatanTkit1 />
            <Footer />
        </>
        )
    }
}
