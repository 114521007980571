import React from 'react'
import { Row, Col} from 'react-bootstrap'
import './Kurikulum.css';
import ImageKurikulum from 'assets/images/reading.png'
import ImageKurikulum2 from 'assets/images/study.png'

export default function Kurikulum() {
    return (
        <div>
            <section className="Container-Kurikulum">
                <Row className="Kurikulum">
                    <Col sm={12} md={4} className="Kurikulum-Img">
                        <img src={ImageKurikulum} alt="Kurikulum" className="img-fluid img-md
                           "/>
                    </Col>
                    <Col sm={12} md={8} className="Kurikulum-Text position-block" >
                        <h1>Kurikulum</h1>
                        <hr/>
                        <img src={ImageKurikulum} alt="Kurikulum" className="img-fluid img-sm
                           "/>
                        <p> Kami menggunakan K-13 atau Kurikulum 2013 dengan muatan  lokal konsep akidah dan nilai-nilai religi islami dan model pembelajaran sentra dengan konsep merdeka belajar dalam ukhuwah islmaiyah.
                        </p>
                        <p className="p-to-lg"><span className="question">Apa Tujuan K-13 PAUD ?</span> <br/>
                            Kurikulum 2013 Pendidikan Anak Usia Dini bertujuan untuk mendorong perkembangan peserta didik secara optimal melalui pengalaman belajar yang bermakna dan menyenangkan sehingga anak mencapai kompetensi sikap, pengetahuan, dan keterampilan yang mendukung ke-berhasilan di sekolah dan pendidikan pada tahap selanjutnya.
                        </p>
                    </Col>

                    <p className="p-to-md"><span className="question"><b>Apa Tujuan K-13 PAUD ?</b></span> <br/>
                            Kurikulum 2013 Pendidikan Anak Usia Dini bertujuan untuk mendorong perkembangan peserta didik secara optimal melalui pengalaman belajar yang bermakna dan menyenangkan sehingga anak mencapai kompetensi sikap, pengetahuan, dan keterampilan yang mendukung ke-berhasilan di sekolah dan pendidikan pada tahap selanjutnya.
                        </p>
                </Row>


                <Row className="Kurikulum Kurikulum-2">
                    <Col sm={12} md={8} className="Kurikulum-Text position-block" >
                        
                        <h5><span className="question">Muatan Kurikulum Paud</span></h5>
                        <p>Muatan kurikulum berisi program-program pengembangan, yang terdiri atas:
                        </p>
                        <ul className="muatan-kurikulum">
                            <li> Program  pengembangan nilai agama dan moral,</li>
                            <li> Program pengembangan fisik motorik,</li>
                            <li> Program pengembangan kognitif,</li>
                            <li> Program pengembangan bahasa, </li>
                            <li> Program pengembangan sosial-emosional, dan </li>
                            <li> Program pengembangan seni </li>
                            
                        </ul>
                        
                    </Col>
                    <Col sm={12} md={4} className="Kurikulum-Img-Right">
                        <img src={ImageKurikulum2} alt="Room with couches" className="img-fluid 
                            position-absolute" style={{margin:'0 0 0 0'}}/>
                    </Col>
                </Row>
            </section>
        </div>
    )
}
