
import React from 'react'
import { Zoom } from 'react-reveal'
import './Quotes.css'

export default function Quotes() {
    return (
        <div>
            <section className="Container-Quotes pb-2">
                <div className="Quotes">
                    <hr />
                    <Zoom delay={100}>
                    <h5>"Anak adalah amanah dan karunia dari Allah kepada setiap keluarga. Orang tua mempunyai kewajiban mengasuh, mendidik dan mengajar agar berakhlakul karimah sehingga menjadi cahaya mata dalam kehidupan orang tua."
                    </h5>
                    <h4>- Alm Drs. KH. Djabir Dadang, M.Ag</h4>
                    </Zoom>
                    <hr />
                </div>
            </section>
        </div>
    )
}
