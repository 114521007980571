import React from 'react'
import { Row} from 'react-bootstrap'
import './Yayasan.css';

export default function Yayasan() {
    return (
        <div>
            <section className="Container-Yayasan">
                <Row className="Yayasan">
                        <h1>Yayasan Pendidikan Insan <br/>Istiqomah</h1>
                </Row>
                <Row className="Yayasan">
                    <p>
                    Yayasan Pendidikan Insan Istiqomah yang selanjutnya disebut Yayasan adalah lembaga yang berkedudukan di Tangerang, yang bergerak dibidang pendidikan pra sekolah dan pendidikan Dasar.
                    Yayasan yang dimaksud adalah Yayasan Pendidikan Insan Istiqomah dan semua unit kegiatan atau usaha yang ada didalamnya.
                    <br /> Sekolah Al - Istiqomah adalah unit dibidang pendidikan yang dibentuk di bawah pengelolaan penuh Yayasan.
                   </p>
                </Row>
            </section>
        </div>
    )
}
