import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Ekskul1 from 'assets/images/ekskul/dance.png'
import Ekskul2 from 'assets/images/ekskul/melukis.png'
import Ekskul3 from 'assets/images/ekskul/parade.png'
import '../CSSTKIT/Ekskul.css'

export default function EkskulTkit2() {
    return (
        <div>
            <section className="Container-Ekskul">
                <Row className="Ekskul">
                        <h1>Ekstakurikuler</h1>
                        <hr/>
                </Row>

                <Row className="Ekskul-Content justify-content-center pb-2">
                    <Col xs={6} md={4}>
                        <div className="Card Card-Ekskul  Tahunan">
                            <img src={Ekskul1} className="Card-Img" />
                            <div className="Card-Body Ekskul-Text">
                                <h5 className="Card-Title">Menari</h5>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={4}>
                        <div className="Card Card-Ekskul  Tahunan">
                            <img src={Ekskul2} className="Card-Img" />
                            <div className="Card-Body Ekskul-Text">
                                <h5 className="Card-Title">Melukis</h5>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={4}>
                        <div className="Card Card-Ekskul  Tahunan">
                            <img src={Ekskul3} className="Card-Img" />
                            <div className="Card-Body Ekskul-Text">
                                <h5 className="Card-Title">Marching Band</h5>
                            </div>
                        </div>
                    </Col>
                </Row>
                </section>
        </div>
    )
}
